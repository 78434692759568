<!-- This is a replacement component for 'HeightExpand' -->
<script setup lang="ts">
import type { TransitionMode } from '~/types/TransitionMode'
import type { RendererElement } from 'vue'

type Props = {
  mode?: TransitionMode
  durationEnter?: number
  durationLeave?: number
}
type Emits = {
  'done-entering': []
  'done-leaving': []
  'start-entering': []
  'start-leaving': []
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'out-in',
  durationEnter: 150,
  durationLeave: 150,
})

const emit = defineEmits<Emits>()

const isExpanded = ref(false)

function applyTimingEnter(element: RendererElement) {
  const shouldReduceMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)',
  ).matches
  element.style.transitionDuration = `${
    shouldReduceMotion ? 0 : props.durationEnter
  }ms`
}

function applyTimingLeave(element: RendererElement) {
  const shouldReduceMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)',
  ).matches
  element.style.transitionDuration = `${
    shouldReduceMotion ? 0 : props.durationLeave
  }ms`
}

function onEnter(element: RendererElement) {
  emit('start-entering')

  const width = element.offsetWidth

  element.style.width = `${width}px`
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  // get the element's height before immediately setting it back to 0
  const height = element.offsetHeight

  element.style.width = ''
  element.style.position = ''
  element.style.visibility = ''
  element.style.height = '0'

  requestAnimationFrame(() => {
    element.style.height = `${height}px`
    isExpanded.value = true
  })
}

function onAfterEnter(element: RendererElement) {
  element.style.height = 'auto'
  emit('done-entering')
}

function onLeave(element: RendererElement) {
  emit('start-leaving')
  const height = element.offsetHeight

  element.style.height = `${height}px`

  requestAnimationFrame(() => {
    element.style.height = '0'
    isExpanded.value = false
  })
}

function onAfterLeave() {
  emit('done-leaving')
}
</script>

<template>
  <Transition
    name="height-expand"
    :mode="mode"
    @before-enter="applyTimingEnter"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @before-leave="applyTimingLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <slot />
  </Transition>
</template>

<style scoped lang="scss">
.height-expand-enter-active,
.height-expand-leave-active {
  @apply tw-overflow-hidden tw-transition-all tw-ease-in-out;
  transition-duration: unset;
}
</style>
